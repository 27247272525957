import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Download from "../Downloads/Download";
// import Testimonial from "../Testimonial/Testimonial";
import FAQs from "../FAQ/FAQs";
import LandingPage from "../LandingPage/LandingPage";
import LandingPageContent from "../Landing_page_contents/LandingPageContent";
import HowItWorks, {
  HowItWorks2,
  HowItWorks3,
  HowItWorks4,
} from "../HowItWorks/HowItWorks";
import "./Home.css";

function Home() {
  return (
    <div className='Home'>
      <Navbar />
      <LandingPage />
      <LandingPageContent />
      <HowItWorks />
      <HowItWorks2 />
      <HowItWorks4 />
      <HowItWorks3 />
      <ChoosePlan />
      <Download />
      {/* <Testimonial /> */}
      <FAQs />
      <Footer />
    </div>
  );
}

export default Home;

export const ChoosePlan = () => {
  const [activePlan, setActivePlan] = useState("Monthly");
  const data = [
    {
      title: "Freemium",
      amount: "₦0",
      amount2: "₦0",
      list: [
        "20 free local transfer",
        "⁠No global transfer",
        "No local investment ",
        "No Global investment",
      ],
    },
    {
      title: "Starter",
      amount: "₦2000",
      amount2: "₦20,000",
      list: [
        "No local transfer fees",
        "Zero global transfer fees (up to $2k monthly)",
        "Fee-free local investment",
        "Fee-free Global investment",
      ],
    },
    {
      title: "Premuim",
      amount: "₦7,500",
      amount2: "₦75,000",
      list: [
        "No local transfer fees",
        "Zero global transfer fees (up to $5k monthly)",
        "Fee-free local investment",
        "Fee-free Global investment",
        "Interest-free credit line (up to NGN100,0000)",
      ],
    },
    {
      title: "Elite",
      amount: "₦30,000",
      amount2: "₦300,000",
      list: [
        "No local transfer fees",
        "⁠Zero global transfer fees (up to $7k monthly)",
        "Fee-free local investment",
        "Fee-free Global investment",
        "⁠Interest-free credit line (up to NGN500,0000)",
      ],
    },
    {
      title: "Infinite",
      amount: "₦60,000",
      amount2: "₦600,000",
      list: [
        "No local transfer fees",
        "⁠Free global transfer fees (up to $10k monthly)",
        "Fee-free local investment",
        "Fee-free Global investment",
        "Interest-free credit line (up to NGN1,000,000)",
      ],
    },
  ];
  return (
    <div className='choose-plan'>
      <h2>Choose your plan</h2>
      <div className='plan-tab'>
        <div
          onClick={() => setActivePlan("Monthly")}
          className={activePlan === "Monthly" ? "ptb1" : "ptb2"}
        >
          Monthly plan
        </div>
        <div
          onClick={() => setActivePlan("Annual")}
          className={activePlan === "Monthly" ? "ptb2" : "ptb1"}
        >
          Annual plan
        </div>
      </div>
      <div className='plans'>
        {data.map((dt, index) => (
          <div
            key={index}
            className={index < 3 ? "plans-card" : "plans-card-2"}
            // style={{
            //   backgroundColor: `${index === 1 ? "#3861FB" : "white"}`,
            //   color: `${index === 1 ? "#ffffff" : "#000000"}`,
            // }}
          >
            <p>{dt.title}</p>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <span>{activePlan === "Monthly" ? dt.amount : dt.amount2}</span>
              <p style={{ fontSize: "18px" }}>
                {" "}
                {activePlan === "Monthly" ? "/month" : "/year"}
              </p>
            </div>
            <ul style={{ listStyleType: "disc" }} className='list-style'>
              {dt.list.map((li, index) => (
                <li
                  style={{ listStyleType: "disc", marginLeft: "18px" }}
                  key={index}
                >
                  {li}
                </li>
              ))}
            </ul>
            <img
              className='arrow-right'
              src='/Images/arrow-right.svg'
              alt='arrow-right'
            />
          </div>
        ))}
      </div>
    </div>
  );
};
